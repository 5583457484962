<template>
  <Menubar :model="items">
  </Menubar>
</template>

<script>
import Menubar from 'primevue/menubar'
import { StorageService } from '@/services/storage.service'
import StorageConstants from '@/constants/storage.constants'
import AuthConstants from '@/constants/auth.constants'
import ApiService from '@/services/api.service'

export default {
  data () {
    return {
      username: '',
      loginApiStatus: StorageService.get(StorageConstants.LoginStatusApiKey) || ''
    }
  },
  name: 'UserSection',
  components: {
    Menubar
  },
  mounted () {
    this.fetchUserData()
  },
  methods: {
    handleLogin () {
      this.$router.push({ name: 'Login' })
    },
    handleLogout () {
      StorageService.save(StorageConstants.LoginStatusApiKey, AuthConstants.LoginApiStatusLogout)
      ApiService.post('/auth/logout')

      this.$router.push({ name: 'Dashboard' })
        .then(() => {
          window.location.reload()
        })
    },
    fetchUserData () {
      this.username = ''
    }
  },
  computed: {
    isLoggedIn () {
      return this.loginApiStatus === 'success'
    },
    items () {
      if (this.isLoggedIn) {
        return [
          {
            label: 'Hello ' + this.username,
            icon: 'pi pi-fw pi-user',
            items: [
              // {
              //   label: 'Dashboard',
              //   icon: 'pi pi-fw pi-home',
              //   command: () => this.$router.push({ name: 'UserDashboard' })
              // },
              {
                label: 'Subscription',
                icon: 'pi pi-fw pi-credit-card',
                command: () => this.$router.push({ name: 'UserSubscription' })
              },
              {
                label: 'Logout',
                icon: 'pi pi-fw pi-sign-out',
                command: () => this.handleLogout()
              }
            ]
          }
        ]
      } else {
        return [
          {
            label: 'Login',
            icon: 'pi pi-fw pi-sign-in',
            command: () => this.handleLogin()
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.p-menubar {
  border: none;
  background: none;
}
.p-menubar {
  border: none;
  background: none;
}

.image-item {
  display: flex;
  align-items: center;
}

.image-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.image-item span {
  margin-left: 8px;
}
</style>
