<template>
  <div>
    <div class="dashboard-container">
      <div class="half-section">
        <FileDistribution />
      </div>
      <div class="half-section">
        <RevenueDistribution />
      </div>
    </div>
<!--    <div class="dashboard-container">-->
<!--      <div class="full-section">-->
<!--        <div class="chart-title">Last year activity-->
<!--          <img :src="require('@/assets/images/icons/arrow.svg')" alt="Chart Image" />-->
<!--        </div>-->

<!--        <ActivityDistribution />-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import FileDistribution from '@/components/User/Dashboard/FileDistribution.vue'
import RevenueDistribution from '@/components/User/Dashboard/RevenueDistribution.vue'

export default {
  components: {
    FileDistribution,
    RevenueDistribution
  }
}
</script>

<style scoped>
.dashboard-container {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  justify-content: flex-start;
}

.half-section {
  width: 45%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-section {
  margin-top: 30px;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chart-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 100px;
  margin-bottom: 10px;
  align-self: flex-start;
}
@media (max-width: 768px) {
  .half-section {
    width: 100%;
    height: auto;
  }
  .full-section {
    width: 100%;
    height: auto;
  }
}
.arrow{
  width: 500px;
}
.chart-title img {
  margin-left: 80px;
  width: 100px;
}
</style>
