<template>
  <div>
    <RegisterForm/>
  </div>
</template>

<script>
import RegisterForm from '@/components/Auth/RegisterForm'
export default {
  name: 'Register',
  components: { RegisterForm }
}
</script>

<style scoped>
</style>
