<template>
  <div class="subscription-container">
    <div class="subscription-card free-plan">
      <img
        class="icon"
        :src="require('@/assets/images/sub/rechnungsfuchs_logo_1.png')"
        alt="Sub Monthly Icon">
      <div class="card-content">
        <h2><strong>Rechnungsfuchs</strong></h2>
        <p>Premium</p>
        <p class="price"><span>9,99€</span> /Month</p>

        <ul class="bulletin">
          <li>PDF Rechnungsgenerator</li>
          <li>Email-Support</li>
          <li>Einfach zu bedienen</li>
          <li>Online-Zahlung ohne Gebühren</li>
          <li>Schnelle Bearbeitung</li>
        </ul>
      </div>
      <div class="button-container">
        <button class="main-button" @click="subscribe('monthly')">Subscribe</button>
      </div>
    </div>

    <div class="subscription-card premium-plan">
      <img
        class="icon"
        :src="require('@/assets/images/sub/rechnungsfuchs_logo_2.jpg')"
        alt="Sub Yearly Icon">
      <div class="card-content">
        <h2>Rechnungsfuchs</h2>
        <p>Premium</p>
        <p class="price"><span>107,88€</span> /Year</p>

        <ul class="bulletin">
          <li>PDF Rechnungsgenerator</li>
          <li>Email-Support</li>
          <li>Einfach zu bedienen</li>
          <li>Online-Zahlung ohne Gebühren</li>
          <li>Schnelle Bearbeitung</li>
        </ul>
      </div>
      <div class="button-container">
        <button class="main-button" @click="subscribe('yearly')">Subscribe</button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/api.service'
import { loadStripe } from '@stripe/stripe-js'

export default {
  name: 'SubscriptionPlans',
  async mounted () {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_TOKEN)
  },
  methods: {
    async subscribe (subPlan) {
      try {
        const userResponse = await ApiService.get('/user/status')

        const response = await ApiService.post('/subscription/session', {
          email: userResponse.data.email,
          subPlan: subPlan
        })

        const { error } = await this.stripe.redirectToCheckout({
          sessionId: response.data.sessionId
        })

        if (error) {
          console.error('Stripe checkout error', error)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'Login' })
        }
      }
    }
  }
}
</script>

<style scoped>
.subscription-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  flex-wrap: wrap;
}

.subscription-card {
  width: 350px;
  height: 720px;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  color: #000;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.free-plan {
  background-color: #ff4d00;
}

.premium-plan {
  background-color: #52ff40;
}

.icon {
  width: 100px;
  height: 95px;
  margin: 10px auto;
  border-radius: 8px;
}

.card-content {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  text-shadow: 0 0 1px black;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
}

h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.0em;
  margin: 10px 0;
  font-weight: 900 !important;
}

.price {
  font-size: 32px;
  font-weight: 900;
  margin: 10px 0;
  color: black;
}

.price span {
  text-shadow: 0 0 1px #FF4D00;
  color: #FF4D00;
}

.bulletin {
  list-style-type: disc;
  padding-left: 40px;
  margin-top: 15px;
  text-align: left;
}

.bulletin li {
  margin: 15px 0;
  font-size: 1.1em;
  color: black;
}

.button-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.main-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  width: 100%;
}

.main-button:hover {
  opacity: 0.8;
}

.free-plan .main-button {
  background-color: white;
  color: #ff5722;
  font-weight: bold;
}

.premium-plan .main-button {
  background-color: white;
  color: #4caf50;
  font-weight: bold;
}

/* Responsive styles */
@media (max-width: 768px) {
  .subscription-container {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .subscription-card {
    width: 100%;
    max-width: 90%;
    height: auto;
  }

  .icon {
    width: 80px;
    height: 75px;
  }

  .price {
    font-size: 24px;
  }

  .bulletin li {
    font-size: 0.95em;
  }

  .main-button {
    font-size: 0.9em;
  }
}
</style>
