<template>
  <div>
    <PasswordResetForm/>
  </div>
</template>

<script>
import PasswordResetForm from '@/components/Auth/PasswordResetForm'
export default {
  name: 'PasswordReset',
  components: { PasswordResetForm }
}
</script>

<style scoped>
</style>
