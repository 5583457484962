<template>
  <div class="password-reset-form">
    <h2>
      <img
        class="fox-logo-row"
        :src="require('@/assets/images/rechnungsfuchs_logo_langform_schwarz.png')"
        alt="Rechnungsfuchs Logo">
    </h2>
    <v-row>
      <v-col>
        <v-alert
          v-show="showMessage.passwordResetMailSent"
          dense
          outlined
          prominent
          text
          type="success"
        >
          <div v-html="$t('component.PasswordResetForm.message.passwordResetMailSent')"></div>
        </v-alert>
        <v-alert
          v-show="showMessage.userNotExists"
          dense
          outlined
          prominent
          text
          type="warning"
        >
          <div>{{$t('component.PasswordResetForm.message.userNotExists')}}!</div>
        </v-alert>
        <v-alert
          v-show="showMessage.unexpectedError"
          dense
          outlined
          prominent
          text
          type="error"
        >
          <div>{{$t('errorMessage.unexpectedError')}}!</div>
        </v-alert>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid">
      <div class="p-fluid">
        <div class="p-field" style="margin-bottom: 30px">
          <label for="username">Email</label>
          <InputText id="email"
            v-model="email"
            required
          />
          <small v-if="emailError" class="p-error">{{ emailError }}</small>
        </div>

        <div class="p-field">
          <Button
            label="Reset Password"
            icon="pi pi-check"
            @click="this.passwordReset"
            class="login-button"
          />
        </div>
        <div class="p-text-center">
          <p>
            <router-link to="/auth/register" class="link register-link">Don't have an account? <strong>Register</strong></router-link>
          </p>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import AuthService, { UserNotFoundException } from '@/services/auth.service'
import { UnexpectedServerError } from '@/exceptions/http.exceptions'

export default {
  name: 'PasswordResetForm',
  data: function () {
    return {
      valid: false,
      loading: false,
      email: '',
      emailError: '',
      showPassword: false,
      showMessage: {
        passwordResetMailSent: false,
        userNotExists: false,
        unexpectedError: false
      }
    }
  },
  methods: {
    async passwordReset () {
      this.loading = true
      this.resetMessages()

      if (this.validateForm()) {
        try {
          await AuthService.resetPassword(this.email)
          this.showMessage.passwordResetMailSent = true
        } catch (e) {
          if (e instanceof UserNotFoundException) {
            this.showMessage.userNotExists = true
          } else if (e instanceof UnexpectedServerError) {
            this.showMessage.unexpectedError = true
          }
        }
      }
      this.loading = false
    },
    validateForm () {
      let isValid = true

      this.emailError = ''
      this.passwordError = ''
      this.passwordConfirmError = ''

      if (!this.email) {
        this.emailError = this.$i18n.t('errorMessage.emailRequired')
        isValid = false
      } else if (!/.+@.+\..+/.test(this.email)) {
        this.emailError = this.$i18n.t('errorMessage.emailNotValid')
        isValid = false
      }

      return isValid
    },
    resetMessages () {
      this.showMessage.passwordResetMailSent = false
      this.showMessage.userNotExists = false
      this.showMessage.unexpectedError = false
    }
  }
}
</script>

<style scoped>
.password-reset-form {
  width: 33%;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.p-field {
  margin-bottom: 1rem;
}

h2 {
  text-align: center;
  margin-bottom: 1rem;
}

/* Button color customization */
.login-button {
  background-color: #F97316;  /* Set the button color */
  border: none;
  color: white;
}

/* Hover effect for the button */
.login-button:hover {
  background-color: #d76210;  /* Darker shade on hover */
}

/* Styling for the links */
.p-text-center {
  text-align: center;
  margin-top: 1rem;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Specific styles for the Register and Forgot Password links */
.register-link {
  color: black;
}

.forgot-password-link {
  color: black;  /* Change text color for Forgot Password to black */
}

.register-link strong {
  font-weight: bold;  /* Make Register text bold */
}

@media (max-width: 768px) {
  .password-reset-form {
    width: 80%;  /* Make form take 80% width on small screens */
  }
}
</style>
