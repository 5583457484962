<template>
  <div class="login-form">
    <h2>
      <img
      class="fox-logo-row"
      :src="require('@/assets/images/rechnungsfuchs_logo_langform_schwarz.png')"
      alt="Rechnungsfuchs Logo">
    </h2>
    <v-row>
      <v-col>
        <v-alert
          v-show="showMessage.successfulRegister"
          dense
          outlined
          prominent
          text
          type="success"
        >
          <div>{{$t('component.loginForm.message.RegisterSuccess')}}!</div>
        </v-alert>
        <v-alert
          v-show="showMessage.successfulLogin"
          dense
          outlined
          prominent
          text
          type="success"
        >
          <div>{{$t('component.loginForm.message.loginSuccess')}}!</div>
        </v-alert>
        <v-alert
          v-show="showMessage.userNotFound"
          dense
          outlined
          prominent
          text
          type="warning"
        >
          <div>{{$t('component.loginForm.message.emailNotFound')}}!</div>
        </v-alert>
        <v-alert
          v-show="showMessage.passwordIncorrect"
          dense
          outlined
          prominent
          text
          type="error"
        >
          <div>{{$t('component.loginForm.message.passwordNotValid')}}!</div>
        </v-alert>
        <v-alert
          v-show="showMessage.accountInactive"
          dense
          outlined
          prominent
          text
          type="error"
        >
          <div>{{$t('component.loginForm.message.accountInactive')}}!</div>
        </v-alert>
        <v-alert
          v-show="showMessage.unexpectedError"
          dense
          outlined
          prominent
          text
          type="error"
        >
          <div>{{$t('errorMessage.unexpectedError')}}!</div>
        </v-alert>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid">
      <div class="p-fluid">
        <div class="p-field">
          <label for="username">Email</label>
          <InputText id="email"
            v-model="email"
            required
          />
          <small v-if="emailError" class="p-error">{{ emailError }}</small>
        </div>

        <div class="p-field">
          <label for="password">Password</label>
          <Password
            v-model="password"
            :toggleMask="true"
            :feedback="false"
            name="input-10-1"
          />
          <small v-if="passwordError" class="p-error">{{ passwordError }}</small>
        </div>

        <div class="p-field">
          <Button
            label="Login"
            icon="pi pi-check"
            @click="this.login"
            class="login-button"
          />
        </div>

        <div class="p-text-center">
          <p>
            <router-link to="/auth/register" class="link register-link">Don't have an account? <strong>Register</strong></router-link>
          </p>
          <p>
            <router-link to="/auth/password-reset" class="link forgot-password-link">Forgot your password?</router-link>
          </p>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>

import AuthService, {
  UserNotFoundException,
  WrongCredentialsException,
  AccountInactiveException
} from '@/services/auth.service'
import { UnexpectedServerError } from '@/exceptions/http.exceptions'

export default {
  name: 'LoginForm',
  data: function () {
    return {
      loading: false,
      valid: false,
      showPassword: false,
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
      showMessage: {
        successfulRegister: false,
        successfulLogin: false,
        userNotFound: false,
        passwordIncorrect: false,
        accountInactive: false,
        unexpectedError: false
      }
    }
  },
  created () {
    if (this.$route.query.source === 'register') {
      this.showMessage.successfulRegister = true
    }
  },
  methods: {
    async login () {
      this.resetMessages()

      if (this.validateForm()) {
        try {
          await AuthService.loginUser(this.email, this.password)
          this.showMessage.successfulLogin = true
          window.location.href = this.$router.resolve({ name: 'dashboard.invoice' }).href
        } catch (e) {
          console.log(e)
          if (e instanceof UserNotFoundException) {
            this.showMessage.userNotFound = true
          } else if (e instanceof WrongCredentialsException) {
            this.showMessage.passwordIncorrect = true
          } else if (e instanceof AccountInactiveException) {
              this.showMessage.accountInactive = true
          } else if (e instanceof UnexpectedServerError) {
            this.showMessage.unexpectedError = true
          }
        }
      }

      this.loading = false
    },

    validateForm () {
      let isValid = true

      this.emailError = ''
      this.passwordError = ''

      // Email validation
      if (!this.email) {
        this.emailError = this.$i18n.t('errorMessage.emailRequired')
        isValid = false
      } else if (!/.+@.+\..+/.test(this.email)) {
        this.emailError = this.$i18n.t('errorMessage.emailNotValid')
        isValid = false
      }

      if (!this.password) {
        this.passwordError = this.$i18n.t('errorMessage.passwordRequired')
        isValid = false
      }

      return isValid
    },

    resetMessages () {
      this.showMessage.successfulLogin = false
      this.showMessage.userNotFound = false
      this.showMessage.passwordIncorrect = false
      this.showMessage.unexpectedError = false
      this.showMessage.accountInactive = false
      this.showMessage.successfulRegister = false
    },
    trackLogin () {
      this.$gtag.event('login_completed', {
        event_category: 'auth',
        event_label: this.email,
        value: null
      })
    }
  }
}
</script>

<style scoped>
.login-form {
  width: 33%;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.p-field {
  margin-bottom: 1rem;
}

h2 {
  text-align: center;
  margin-bottom: 1rem;
}

/* Button color customization */
.login-button {
  background-color: #F97316;  /* Set the button color */
  border: none;
  color: white;
}

/* Hover effect for the button */
.login-button:hover {
  background-color: #d76210;  /* Darker shade on hover */
}

/* Styling for the links */
.p-text-center {
  text-align: center;
  margin-top: 1rem;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Specific styles for the Register and Forgot Password links */
.register-link {
  color: black;
}

.forgot-password-link {
  color: black;  /* Change text color for Forgot Password to black */
}

.register-link strong {
  font-weight: bold;  /* Make Register text bold */
}

@media (max-width: 768px) {
  .login-form {
    width: 80%;  /* Make form take 80% width on small screens */
  }
}
</style>
