<template>
  <div class="subscription-container">
    <div v-if="loading" class="loading">
      <p>Loading subscription details...</p>
    </div>

    <div v-else>
      <div v-if="isSubscribed" class="subscription-card active">
        <img
          class="icon"
          :src="require('@/assets/images/sub/rechnungsfuchs_logo_1.png')"
          alt="Subscription Icon">
        <div class="card-content">
          <h2>Subscription</h2>
          <p><strong>Status:</strong> Active</p>
          <p><strong>Plan:</strong> {{ plan }}</p>
          <p><strong>Subscribed since:</strong> {{ subscriptionStart }}</p>
        </div>
        <div class="button-container">
          <button @click="cancelSubscription" class="cancel-button">Cancel Subscription</button>
        </div>
      </div>

      <div v-else class="subscription-card not-subscribed">
        <h2><strong>You don't have active subscription</strong></h2>
        <router-link to="/subscription/checkout" class="link">
          <button class="main-button">Subscribe Now</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/api.service'

export default {
  name: 'SubscriptionManagement',
  data () {
    return {
      isSubscribed: false,
      subscriptionDetails: null,
      subscriptionStart: null,
      plan: null,
      loading: true
    }
  },
  methods: {
    async fetchSubscriptionStatus () {
      try {
        const response = await ApiService.get('/user/subscription')
        this.isSubscribed = true
        this.subscriptionStart = this.formatDate(response.data.subscription.subscribedSince.date)

        this.plan = 'monthly'
      } catch (error) {
        console.error('Failed to fetch subscription status:', error)
      } finally {
        this.loading = false
      }
    },
    formatDate (dateString) {
      const date = new Date(dateString)

      return date.toLocaleString()
    },
    async cancelSubscription () {
      alert('Your subscription is canceled')
    }
  },
  mounted () {
    this.fetchSubscriptionStatus()
  }
}
</script>

<style scoped>
.subscription-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 30px;
  flex-wrap: wrap;
}

.subscription-card {
  width: 550px;
  height: auto;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  color: #000;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.active {
  background-color: #f0f8ff;
}

.not-subscribed {
  color: black;
  font-weight: bolder;
  text-align: center;
  padding: 40px;
  border-radius: 8px;
}

.icon {
  width: 100px;
  height: 95px;
  margin: 10px auto;
  border-radius: 8px;
}

.card-content {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
}

h2 {
  font-size: 1.2em;
  margin: 10px 0;
  font-weight: bold;
}

.price {
  font-size: 32px;
  font-weight: 900;
  margin: 10px 0;
  color: black;
}

.bulletin {
  list-style-type: disc;
  padding-left: 40px;
  margin-top: 15px;
  text-align: left;
}

.bulletin li {
  margin: 15px 0;
  font-size: 1.1em;
  color: black;
  font-weight: bold;
}

.button-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.main-button,
.cancel-button {
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  width: 100%;
  transition: background-color 0.3s ease;
}

.main-button {
  background-color: #388e3c;
  color: white;
  font-weight: bold;
}

.main-button:hover {
  background-color: #388e3c;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  font-weight: bold;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

.not-subscribed h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.link {
  text-decoration: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  .subscription-container {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .subscription-card {
    width: 100%;
    max-width: 90%;
    height: auto;
  }

  .icon {
    width: 80px;
    height: 75px;
  }

  .main-button,
  .cancel-button {
    font-size: 0.9em;
  }
}
</style>
