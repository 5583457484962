<template>
  <div class="verify-session">
    <div v-if="loading" class="loading">Verifying your session...</div>
    <div v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import AuthService, {} from '@/services/auth.service'

export default {
  name: 'VerifySession',
  data () {
    return {
      loading: true,
      error: null
    }
  },
  async created () {
    const sessionId = this.$route.params.sessionId
    try {
      await AuthService.verifyRegister(sessionId)

      this.$router.push({
        path: '/auth/login',
        query: { source: 'register' }
      })
    } catch (e) {
      this.error = 'An error occurred while verifying your session'
    } finally {
      this.loading = false
    }
  }
}
</script>

<style>
.loading {
  font-size: 18px;
  color: #555;
  text-align: center;
}
.error {
  color: red;
  font-size: 16px;
  text-align: center;
}
</style>
