<template>
  <div class="register-form">
    <h2>
      <img
        class="fox-logo-row"
        :src="require('@/assets/images/rechnungsfuchs_logo_langform_schwarz.png')"
        alt="Rechnungsfuchs Logo">
    </h2>
    <v-row>
      <v-col>
        <v-alert
          v-show="showMessage.registerSuccessful"
          dense
          outlined
          prominent
          text
          type="success"
        >
          <div v-html="$t('component.registerForm.message.registerSuccess', {here: loginLink})"></div>
        </v-alert>
        <v-alert
          v-show="showMessage.userAlreadyExists"
          dense
          outlined
          prominent
          text
          type="warning"
        >
          <div>{{$t('component.registerForm.message.userAlreadyExists')}}!</div>
        </v-alert>
        <v-alert
          v-show="showMessage.unexpectedError"
          dense
          outlined
          prominent
          text
          type="error"
        >
          <div>{{$t('errorMessage.unexpectedError')}}!</div>
        </v-alert>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid">
      <div class="p-fluid">
        <div class="p-field">
          <label for="username">Email</label>
          <InputText id="email"
            v-model="email"
            :rules="[rules.required, rules.emailRules]"
            required
          />
          <small v-if="emailError" class="p-error">{{ emailError }}</small>
        </div>

        <div class="p-field">
          <label for="password">Password</label>
          <Password
            v-model="password"
            :toggleMask="true"
            :feedback="true"
            :mediumRegex="mediumRegex"
            name="input-10-1"
          >
            <template #header>
            </template>
            <template #footer>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                <li>At least one lowercase</li>
                <li>At least one uppercase or one number</li>
                <li>Minimum 6 characters</li>
              </ul>
            </template>
          </Password>

          <small v-if="passwordError" class="p-error">{{ passwordError }}</small>
        </div>

        <div class="p-field">
          <label for="password">Confirm Password</label>
          <Password
            v-model="confirmPassword"
            :toggleMask="true"
            :feedback="false"
            name="input-10-1"
          />
          <small v-if="passwordConfirmError" class="p-error">{{ passwordConfirmError }}</small>
        </div>

        <div class="p-field">
          <Button
            label="Register"
            icon="pi pi-check"
            @click="this.register"
            class="login-button"
          />
        </div>
        <div class="p-text-center">
          <p>
            <router-link to="/auth/login" class="link register-link">Already have an account? <strong>Login</strong></router-link>
          </p>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import AuthService, { UserAlreadyExists } from '@/services/auth.service'
import { UnexpectedServerError } from '@/exceptions/http.exceptions'

export default {
  name: 'RegisterForm',
  data: function () {
    return {
      valid: false,
      loading: false,
      email: '',
      password: '',
      confirmPassword: '',
      emailError: '',
      passwordError: '',
      passwordConfirmError: '',
      showPassword: false,
      mediumRegex: '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,}).',
      showMessage: {
        registerSuccessful: false,
        userAlreadyExists: false,
        unexpectedError: false
      },
      rules: {
        required: value => !!value || this.$i18n.t('errorMessage.required'),
        min: v => v.length >= 8 || this.$i18n.t('errorMessage.required', { min: 8 }),
        emailRules: v => /.+@.+/.test(v) || this.$i18n.t('errorMessage.emailNotValid')
      }
    }
  },
  computed: {
    loginLink () {
      return `<a href="/auth/login" class="LoginLink">${this.$i18n.t('text.here')}</a>`
    }
  },
  methods: {
    async register () {
      this.loading = true
      this.resetMessages()

      if (this.validateForm()) {
        try {
          await AuthService.registerUser(this.email, this.password)
          this.showMessage.registerSuccessful = true
          this.trackRegister()
        } catch (e) {
          if (e instanceof UserAlreadyExists) {
            this.showMessage.userAlreadyExists = true
          } else if (e instanceof UnexpectedServerError) {
            this.showMessage.unexpectedError = true
          }
        }
      }
      this.loading = false
    },
    validateForm () {
      let isValid = true

      this.emailError = ''
      this.passwordError = ''
      this.passwordConfirmError = ''

      if (!this.email) {
        this.emailError = this.$i18n.t('errorMessage.emailRequired')
        isValid = false
      } else if (!/.+@.+\..+/.test(this.email)) {
        this.emailError = this.$i18n.t('errorMessage.emailNotValid')
        isValid = false
      }
      if (this.password !== this.confirmPassword) {
        this.passwordConfirmError = this.$i18n.t('errorMessage.passwordConfirmNotMatch')
        isValid = false
      }
      if (!this.password) {
        this.passwordError = this.$i18n.t('errorMessage.passwordRequired')
        isValid = false
      }
      const passwordRegex = new RegExp(this.mediumRegex)
      if (!passwordRegex.test(this.password)) {
        this.passwordError = this.$i18n.t('errorMessage.passwordWeak')
        isValid = false
      }

      return isValid
    },
    resetMessages () {
      this.showMessage.registerSuccessful = false
      this.showMessage.userAlreadyExists = false
      this.showMessage.unexpectedError = false
    },
    trackRegister () {
      this.$gtag.event('register_completed', {
        event_category: 'auth',
        event_label: this.email,
        value: null
      })
    }
  }
}
</script>

<style scoped>
.register-form {
  width: 33%;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.p-field {
  margin-bottom: 1rem;
}

h2 {
  text-align: center;
  margin-bottom: 1rem;
}

/* Button color customization */
.login-button {
  background-color: #F97316;  /* Set the button color */
  border: none;
  color: white;
}

/* Hover effect for the button */
.login-button:hover {
  background-color: #d76210;  /* Darker shade on hover */
}

/* Styling for the links */
.p-text-center {
  text-align: center;
  margin-top: 1rem;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Specific styles for the Register and Forgot Password links */
.register-link {
  color: black;
}

.forgot-password-link {
  color: black;  /* Change text color for Forgot Password to black */
}

.register-link strong {
  font-weight: bold;  /* Make Register text bold */
}

@media (max-width: 768px) {
  .register-form {
    width: 80%;  /* Make form take 80% width on small screens */
  }
}
</style>
