<template>
  <Chart type="pie" :data="chartData" :options="chartOptions" />
</template>

<script>
import Chart from 'primevue/chart'

export default {
  components: {
    Chart
  },
  data () {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['#42a5f5', '#66bb6a', '#ffa726', '#ab47bc'],
            hoverBackgroundColor: ['#64b5f6', '#81c784', '#ffb74d', '#ba68c8']
          }
        ]
      },
      chartOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        }
      }
    }
  },
  methods: {
    getDynamicData () {
      const data = [
        { label: 'Brief', value: 20 },
        { label: 'Rechnung', value: 30 },
        { label: 'ZUGFeRD', value: 40 },
        { label: 'X-Rechnung', value: 55 }
      ]

      this.chartData.labels = data.map((item) => `${item.label} : ${item.value}`)
      this.chartData.datasets[0].data = data.map((item) => item.value)
    }
  },
  created () {
    this.getDynamicData()
  }
}
</script>

<style scoped></style>
