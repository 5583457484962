<template>
  <div v-if="isValidSession">
    <div class="password-reset-session-form">
      <h2>
        <img
          class="fox-logo-row"
          :src="require('@/assets/images/rechnungsfuchs_logo_langform_schwarz.png')"
          alt="Rechnungsfuchs Logo">
      </h2>
      <v-row>
        <v-col>
          <v-alert
            v-show="showMessage.resetSuccessful"
            dense
            outlined
            prominent
            text
            type="success"
          >
            <div>{{$t('component.PasswordResetForm.message.resetSuccessful')}}!</div>
          </v-alert>
          <v-alert
            v-show="showMessage.unexpectedError"
            dense
            outlined
            prominent
            text
            type="error"
          >
            <div>{{$t('errorMessage.unexpectedError')}}!</div>
          </v-alert>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid">
        <div class="p-fluid">
          <div class="p-field">
            <label for="password">New Password</label>
            <Password
              v-model="password"
              :toggleMask="true"
              :feedback="true"
              :mediumRegex="mediumRegex"
              name="input-10-1"
            >
              <template #header>
              </template>
              <template #footer>
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                  <li>At least one lowercase</li>
                  <li>At least one uppercase or one number</li>
                  <li>Minimum 6 characters</li>
                </ul>
              </template>
            </Password>

            <small v-if="passwordError" class="p-error">{{ passwordError }}</small>
          </div>

          <div class="p-field">
            <label for="password">Confirm Password</label>
            <Password
              v-model="confirmPassword"
              :toggleMask="true"
              :feedback="false"
              name="input-10-1"
            />
            <small v-if="passwordConfirmError" class="p-error">{{ passwordConfirmError }}</small>
          </div>

          <div class="p-field">
            <Button
              label="Confirm"
              icon="pi pi-check"
              @click="this.reset"
              class="login-button"
            />
          </div>
          <div class="p-text-center">
            <p>
              <router-link to="/auth/login" class="link register-link">Already have an account? <strong>Login</strong></router-link>
            </p>
          </div>
        </div>
      </v-form>
    </div>
  </div>
  <div v-else>
    <v-alert dense outlined prominent text type="error">
      {{sessionError}}
    </v-alert>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service'

export default {
  name: 'passwordResetSessionForm',
  data: function () {
    return {
      valid: false,
      email: '',
      password: '',
      confirmPassword: '',
      emailError: '',
      passwordError: '',
      passwordConfirmError: '',
      sessionError: '',
      showPassword: false,
      isValidSession: true,
      mediumRegex: '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,}).',
      showMessage: {
        resetSuccessful: false,
        userAlreadyExists: false,
        unexpectedError: false
      }
    }
  },
  async created () {
    try {
      const isValid = await AuthService.validateSession(this.$route.params.sessionId)
      this.isValidSession = isValid
      if (!isValid) {
        this.sessionError = 'Invalid or expired session. Please request a new password reset.'
      }
    } catch (e) {
      this.isValidSession = false
      this.sessionError = 'An unexpected error occurred while validating the session.'
    }
  },
  methods: {
    async reset () {
      this.resetMessages()

      if (this.validateForm()) {
        try {
          await AuthService.resetPasswordSend(this.$route.params.sessionId, this.password)
          this.showMessage.resetSuccessful = true
        } catch (e) {
          this.showMessage.unexpectedError = true
        }
      }
    },
    validateForm () {
      let isValid = true

      this.passwordError = ''
      this.passwordConfirmError = ''

      if (this.password !== this.confirmPassword) {
        this.passwordConfirmError = this.$i18n.t('errorMessage.passwordConfirmNotMatch')
        isValid = false
      }
      if (!this.password) {
        this.passwordError = this.$i18n.t('errorMessage.passwordRequired')
        isValid = false
      }
      const passwordRegex = new RegExp(this.mediumRegex)
      if (!passwordRegex.test(this.password)) {
        this.passwordError = this.$i18n.t('errorMessage.passwordWeak')
        isValid = false
      }

      return isValid
    },
    resetMessages () {
      this.showMessage.resetSuccessful = false
      this.showMessage.unexpectedError = false
    }
  }
}
</script>

<style scoped>
.password-reset-session-form {
  width: 33%;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  margin: 200px auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.p-field {
  margin-bottom: 1rem;
}

h2 {
  text-align: center;
  margin-bottom: 1rem;
}

/* Button color customization */
.login-button {
  background-color: #f97316; /* Set the button color */
  border: none;
  color: white;
}

/* Hover effect for the button */
.login-button:hover {
  background-color: #d76210; /* Darker shade on hover */
}

/* Styling for the links */
.p-text-center {
  text-align: center;
  margin-top: 1rem;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Specific styles for the Register and Forgot Password links */
.register-link {
  color: black;
}

.forgot-password-link {
  color: black; /* Change text color for Forgot Password to black */
}

.register-link strong {
  font-weight: bold; /* Make Register text bold */
}

@media (max-width: 768px) {
  .register-form {
    width: 80%; /* Make form take 80% width on small screens */
  }
}
</style>
