<template>
  <div>
    <PasswordResetSessionForm/>
  </div>
</template>

<script>
import PasswordResetSessionForm from '@/components/Auth/PasswordResetSessionForm'
export default {
  name: 'PasswordResetSession',
  components: { PasswordResetSessionForm }
}
</script>

<style scoped>
</style>
