<template>
  <div class="login-form">
    <LoginForm/>
  </div>
</template>

<script>
import LoginForm from '@/components/Auth/LoginForm'

export default {
  name: 'Login',
  components: { LoginForm }
}
</script>

<style scoped>
</style>
